/*
 * (C) 2002-2014 ITC Internet-Trade-Center AG
 * All rights reserved.
 */

/**
 * Escape "." and ":" in ids to that they can be used in jQuery selectors.
 */
function jq(myid)
{
    return '#' + myid.replace(/(:|\.)/g, '\\$1');
}

/**
 * Locale variable that indicates if a form is already submitted
 */
var submitted = false;

/**
 * Check if a from have to be submitted. A form can only submitted once until the response is received. This function
 * only works for button tags.
 * 
 * @return <code>true</code> if the form have to be submitted, otherwise <code>false</code>
 */
function submitFormRequired(elementId)
{
    if (!submitted)
    {
        submitted = true;
        return true;
    }
    var button = jQuery('#' + elementId);
    var newButton = button.clone();
    var buttonId = button.attr('id');

    newButton.attr('type', 'button');
    newButton.attr('id', buttonId + 'new');
    newButton.insertBefore(button);
    button.remove();
    newButton.attr('id', buttonId);

    return false;
}

/**
 * Preloads images for instant access.
 * 
 * @param paths an array with all image paths that should be preloaded.
 */
jQuery.preloadImages = function()
{
    for ( var i = 0; i < arguments.length; i++)
    {
        $('<img />').attr('src', arguments[i]);
    }
}

/**
 * Fix that String trim() function is available in ie
 */
if (typeof String.prototype.trim !== 'function')
{
    String.prototype.trim = function()
    {
        return this.replace(/^\s+|\s+$/g, '');
    }
}

/**
 * Format strings in MessageFormat style.
 * 
 * i.e. 'Foo {0}'.format('Bar') -> 'Foo Bar'
 * 
 * @return the formatet string
 */
String.prototype.format = function()
{
    var args = arguments;
    return this.replace(/\{(\d+)\}/g, function()
    {
        return args[arguments[1]];
    });
};

/**
 * Check if a string value is empty.
 * 
 * @param str the string value to check
 * @return <code>true</code> if the value is empty, otherwise <code>false</code>
 */
function isEmpty(str)
{
    return str == 'undefined' || s.trim(str) == '';
}

/**
 * Check if a string value is not empty.
 * 
 * @param str the string value to check
 * @return <code>true</code> if the value is not empty, otherwise <code>false</code>
 */
function isNotEmpty(str)
{
    return !isEmpty(str);
}

/**
 * Nullsave string trim implementation.
 * 
 * @param str the string to trim
 * @return the trimmed string
 */
function trim(str)
{
    return s.trim(str);
}

/**
 * Trim a string value to undefined if it is undefined or empty.
 * 
 * @param str the string to trim
 * @return 'undefined' if the string undefined or empty, otherwise the string
 */
function trimToUndefined(str)
{
    if (str != undefined && isEmpty(str))
    {
        str = undefined;
    }
    return str;
}

/**
 * Create a new date by milliseconds.
 * 
 * @param millis milliseconds since 01.01.1970
 * @returns the JavaScript date
 */
function createDate(millis)
{
    var min = -2208992400000;
    var max = 8640000000000000;
    if (millis < min)
    {
        return new Date(min);
    }
    else if (millis > max)
    {
        return new Date(max);
    }
    else
    {
        return new Date(millis);
    }
}
/**
 * 
 * @param array - source, all elements -> could be html-elements like inputs
 * @param separator - this param will be used as separator in the result string
 * @param attribute - optional -> when elements in the source array are html-objects this param will be used to define
 *            the value-attribute i.e. attribute = 'value' -> <input type='text' name='x' value'target' />
 * 
 * @returns string
 */
function arrayToString(array, separator, attribute)
{
    if (!attribute)
    {
        return array.join(separator);
    }
    else
    {
        var res = _.map(array, function(item)
        {
            return $(item).attr(attribute);
        });
        return res.join(separator);
    }
}

/**
 * jQuery namespace inclusion.
 */
(function($)
{
    jQuery.fn.isEmpty = function()
    {
        return isEmpty($(this).val());
    }

    jQuery.fn.isNotEmpty = function()
    {
        return isNotEmpty($(this).val());
    }
})(jQuery);

/**
 * Reset inputs
 */
(function($)
{
    jQuery.fn.reset = function()
    {
        $(this).each(function()
        {
            var id = $(this).attr('id');
            var tagName = $(this).get(0).tagName;
            if (tagName == 'INPUT')
            {   
               if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio')
               {
                   $(this).attr('checked', false);
               } 
               else
               {
                   $(this).val('');
               }
            }
            else if(tagName == 'SELECT')
            {
                $('#' + id + ' option:selected').removeAttr('selected');
            }
        });
    }
    
})(jQuery);

/**
 * Browser detection
 */
var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
        this.version = this.searchVersion(navigator.userAgent)
            || this.searchVersion(navigator.appVersion)
            || "an unknown version";
        this.OS = this.searchString(this.dataOS) || "an unknown OS";
    },
    searchString: function (data) {
        for (var i=0;i<data.length;i++) {
            var dataString = data[i].string;
            var dataProp = data[i].prop;
            this.versionSearchString = data[i].versionSearch || data[i].identity;
            if (dataString) {
                if (dataString.indexOf(data[i].subString) != -1)
                    return data[i].identity;
            }
            else if (dataProp)
                return data[i].identity;
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index == -1) return;
        return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
    },
    dataBrowser: [
        {
            string: navigator.userAgent,
            subString: "Chrome",
            identity: "Chrome"
        },
        {   string: navigator.userAgent,
            subString: "OmniWeb",
            versionSearch: "OmniWeb/",
            identity: "OmniWeb"
        },
        {
            string: navigator.vendor,
            subString: "Apple",
            identity: "Safari",
            versionSearch: "Version"
        },
        {
            prop: window.opera,
            identity: "Opera",
            versionSearch: "Version"
        },
        {
            string: navigator.vendor,
            subString: "iCab",
            identity: "iCab"
        },
        {
            string: navigator.vendor,
            subString: "KDE",
            identity: "Konqueror"
        },
        {
            string: navigator.userAgent,
            subString: "Firefox",
            identity: "Firefox"
        },
        {
            string: navigator.vendor,
            subString: "Camino",
            identity: "Camino"
        },
        {       // for newer Netscapes (6+)
            string: navigator.userAgent,
            subString: "Netscape",
            identity: "Netscape"
        },
        {
            string: navigator.userAgent,
            subString: "MSIE",
            identity: "Explorer",
            versionSearch: "MSIE"
        },
        {
            string: navigator.userAgent,
            subString: "Gecko",
            identity: "Mozilla",
            versionSearch: "rv"
        },
        {       // for older Netscapes (4-)
            string: navigator.userAgent,
            subString: "Mozilla",
            identity: "Netscape",
            versionSearch: "Mozilla"
        }
    ],
    dataOS : [
        {
            string: navigator.platform,
            subString: "Win",
            identity: "Windows"
        },
        {
            string: navigator.platform,
            subString: "Mac",
            identity: "Mac"
        },
        {
               string: navigator.userAgent,
               subString: "iPhone",
               identity: "iPhone/iPod"
        },
        {
            string: navigator.platform,
            subString: "Linux",
            identity: "Linux"
            }
        ]   
};

