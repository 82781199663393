/*
 * (C) 2002-2014 ITC Internet-Trade-Center AG
 * All rights reserved.
 */
/**
 * Function that renames all buttons of type submit to a default value on document ready. For each but a callback method
 * is created that stores the name of the button and write back the name only if the button is pushed. This approach is
 * necessary because Internet Explorer 6/7 sends all button names in the submitted request independent of which button
 * was pushed. Without that fix event handling is not possible because events are triggered by the name of the submitted
 * button.
 */
$(document).ready(function()
{
    $('body').find('button[type=submit]').each(function()
    {
        this.onclick = createButtonCallback(this);
        this.name = 'btnfx';
    });
});

/**
 * Creates a callback function that write back the name of the button and execute already existing onClick function(s).
 * 
 * @param btn the name of the submit button
 * @return the callback function
 */
function createButtonCallback(btn)
{
    var btnName = btn.name;
    var f = btn.onclick;
    return function()
    {
        this.name = btnName;
        if (f)
        {
            f();
        }
    };
}

/**
 * Search for the next submit button of the current form and set this as default button that is submitted if the user
 * press the enter key in the form. This is commonly an IE issue, because he has no proper default button handling. You
 * have to call the install method in your project template for the base element that should be handled.
 * 
 * i.e. $('#contentContainer').installDefaultButton();
 */
jQuery.fn.installDefaultButton = function()
{
    $(document).on('keypress', "form input, form select,form button[type='button']", function(e)
    {
        if ((e.which && e.which == 13) || (e.keyCode && e.keyCode == 13))
        {    
            if (window.previousKeyCode != null)
            {
                // down=40,up=38,pgdn=34,pgup=33
                if (window.previousKeyCode == 33 || window.previousKeyCode == 34 ||
                    window.previousKeyCode == 39 || window.previousKeyCode == 40)
                {
                    window.previousKeyCode = e.keyCode;
                    return true;
                }
            }
            window.previousKeyCode = e.keyCode;
            if ($(this).closest('form').find('button').length <= 0)
            {
                return true;
            }
            
			var defaultButton = null;
			if($('#defaultButtonId').length > 0)
			{
				defaultButton = '#' + $('#defaultButtonId').val();
			}
			else
			{
	            // Add this prefix to the id of the button which should be executed after return was pressed
	            var prefix = 'autoexec';
	            
	            var currentObject = $(this);
	            var defaultWidgetButton = null;
	            var parentWidgetObjects = $(currentObject).parents('.widget');
	            if (parentWidgetObjects.length == 1 && parentWidgetObjects.attr('default-button') != null)
	            {
	            	var defaultButtonId = parentWidgetObjects.attr('default-button');
	            	defaultButton = $('#' + defaultButtonId);
				}
	            else
            	{
	            	while ($(currentObject).attr('method') != 'post' && $(currentObject)[0].nodeName != 'BODY'
	            		&& $(defaultButton).attr('type') != 'button' && $(defaultButton).attr('type') != 'submit')
	            	{
	            		currentObject = $(currentObject).parent();
	            		defaultButton = $(currentObject).find('button');
	            	}
            	}
	            
	            // click the FIRST button with the given prefix found inside the form
	            $(defaultButton).each(function(index, value)
	            {
	                var id = $(this).attr('id');
					if (typeof id != 'undefined') {
		                var index = id.indexOf(prefix);
		                if (index >= 0 || $(this).data(prefix) == false)
		                {
		                    defaultButton.splice($(this).index(), 1);
		                }
					}
	            });
	            
	            var found = $(defaultButton).length;
	            if (found > 1)
	            {
		                console.error(found +' buttons found and executed! Check "buttonFix.js" to install a default button on this input form to prevent multiple buttons being executed');
	            }
			}

            $(defaultButton).click();
            $(this).blur();
            e.stopImmediatePropagation();
            return false;
        }
        else
        {
            window.previousKeyCode = e.keyCode;
            return true;
        }
    });
    return this;
};